<template>
    <div class="home">
        <!-- <el-alert
                type="warning"
                center>
            <template slot="title">
                新版七尾狐仅支持查看2021.04.19日以来的数据，更多历史数据请前往 <el-link type="primary" @click="versionChange">旧版七尾狐</el-link>
            </template>
        </el-alert> -->
        <div class="header">
            <el-row type="flex" justify="space-between" align="middle">
                <el-col class="logo-col">
                    <img class="logo" src="../assets/qiweihu_logo.png">
                </el-col>
                <el-col class="title-col">
                    <img class="title" src="../assets/qiweihu_title.png">
                </el-col>
                <el-col class="account">
                    <el-dropdown @command="handleCommand" placement="bottom">
                        <span class="el-dropdown-link text">
                          <i class="el-icon-user-solid"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" class="account-menu">
                            <el-dropdown-item icon="el-icon-phone" disabled>{{ user.mobile }}</el-dropdown-item>
                            <el-dropdown-item
                                    icon="el-icon-switch-button"
                                    command="logout"
                            >退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
            </el-row>
        </div>
        <div class="main">
            <el-row type="flex" justify="space-around">
                <el-col class="left-chart" :span="7">
                    <div class="chart-box">
                        <div class="title">账户盈亏</div>
                        <HChart ref="accountProfitCard" class="sm-chart" id="accountProfit"
                                :options="accountProfitOptions"/>
                    </div>
                    <div class="chart-box">
                        <div class="title">每周盈亏</div>
                        <HChart ref="weekProfitCard" class="sm-chart" id="weekProfit"
                                :options="weekProfitOptions"/>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="center-box">
                        <HChart ref="assetsStructCard" id="assetsStruct"
                                :options="structOptions"/>
                        <div class="money-box">
                            <div class="title">总资产</div>
                            <div class="number">￥
                                <countTo :startVal='0' :decimals="2"
                                         :endVal="Number(assetTotal)"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-box">
                        <el-col class="card" :span="10">
                            <span class="title">累计盈亏</span>
                            <span class="number" :class="totalProfit>0?'positive':'negative'">￥<countTo :startVal='0'
                                                                                              :decimals="2"
                                                                                              :endVal="Number(totalProfit)"/></span>
                        </el-col>
                        <el-col class="card" :span="10">
                            <span class="title">今日盈亏</span>
                            <span class="number" :class="todayProfit>0?'positive':'negative'">￥<countTo :startVal='0'
                                                                                              :decimals="2"
                                                                                              :endVal="Number(todayProfit)"/></span>
                        </el-col>
                    </div>
                </el-col>
                <el-col class="right-chart" :span="7">
                    <div class="chart-box">
                        <div class="title">累计净值</div>
                        <HChart class="lg-chart" ref="netWorthCard" id="netWorth" :options="netWorthOptions"/>
                    </div>
                    <div class="chart-box">
                        <div class="title">盈亏曲线</div>
                        <HChart ref="profitCard" class="lg-chart" id="profit" :options="profitOptions"/>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="tabs">
            <el-row type="flex" justify="center">
                <el-col v-for="(item, index) in tabs" :key="item.id" :span="4" class="tab-box"
                        @mouseenter.native="activeTab=index" @click.native="jumpToAnalysis(index)">
                    <div :class="activeTab===index?'tab active':'tab'">
                        <img class="icon" :src="activeTab===index?item.activeIcon:item.icon"/>
                    </div>
                    <span :class="activeTab===index?'text active':'text'">{{ item.text }}</span>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import {Loading} from "element-ui";
  import {mapState} from "vuex";
  import countTo from 'vue-count-to'
  import {apiAnalysisData, apiGeneral} from "../api/analysis";

  export default {
    name: 'home',
    components: {
      HChart,
      countTo
    },
    data () {
      return {
        screenWidth: document.body.clientWidth,
        agreement: '',
        activeTab: 0,
        tabs: [
          // {
          //   id: 0,
          //   text: '资产概况',
          //   icon: require('../assets/home/assets.png'),
          //   activeIcon: require('../assets/home/assets_active.png')
          // },
          {
            id: 1,
            text: '综合统计',
            icon: require('../assets/home/total.png'),
            activeIcon: require('../assets/home/total_active.png')
          },
          {
            id: 2,
            text: '收益分析',
            icon: require('../assets/home/income.png'),
            activeIcon: require('../assets/home/income_active.png')
          },
          {
            id: 3,
            text: '交易特征',
            icon: require('../assets/home/trans.png'),
            activeIcon: require('../assets/home/trans_active.png')
          },
          {
            id: 4,
            text: '品种分析',
            icon: require('../assets/home/single.png'),
            activeIcon: require('../assets/home/single_active.png')
          },
          {
            id: 5,
            text: '原始数据',
            icon: require('../assets/home/origin.png'),
            activeIcon: require('../assets/home/origin_active.png')
          },
          {
            id: 6,
            text: '账户管理',
            icon: require('../assets/home/account.png'),
            activeIcon: require('../assets/home/account_active.png')
          },
          {
            id: 7,
            text: 'K线复盘',
            icon: require('../assets/home/kline.png'),
            activeIcon: require('../assets/home/kline_active.png')
          }
        ],
        assetTotal: 0,
        totalProfit: 0,
        todayProfit: 0,
        weekProfitOptions: {
          credits: {
            enabled: false
          },
          chart: {
            renderTo: 'container',
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 5,
              beta: 0,
              depth: 20,
              viewDistance: 90
            },
            margin: [40, 20, 75, 70],
            height: 280,
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: {
            text: '',
            y: 16,
            style: {
              fontSize: '18px',
              fontWeight: 400,
              color: '#DEF1FF'
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
          },
          colors: ['#ff2d2e'],
          plotOptions: {
            series: {
              negativeColor: '#00933b'
            }
          },
          xAxis: {
            categories: [],
            lineWidth: 1,
            gridLineWidth: 0,
            labels: {
              style: {
                fontFamily: 'PingFang SC',
                fontWeight: 500,
                color: '#999999'
              }
            }
          },
          yAxis: {
            // tickAmount: 4,
            gridLineWidth: 1,
            gridLineColor: 'rgba(101, 198, 231, 0.2)',
            labels: {
              style: {
                color: '#999999'
              }
            },
            title: {
              text: ''
            }
          },
          series: [{
            name: '每周盈亏',
            data: [],
            color: {
              linearGradient: [0, 0, 0, 140],
              stops: [
                [0, '#01F0FF'],
                [1, '#005559']
              ]
            }
          }],
        },
        structOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            margin: [0,0,0,0],
            backgroundColor: null,
            plotBackgroundColor: null
          },
          colors: ['#3240ff', '#3c91ff', '#48e3ff', '#0cffdb'],
          title: {
            floating:true,
            text: '',
          },
          tooltip: {
            pointFormat: '资产：<b>{point.y}</b><br/>{series.name}: <b>{point.percentage:.2f}%</b>',
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 25,
              innerSize: '50%',
              dataLabels: {
                enabled: true,
                distance: -50,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
        accountProfitOptions: {
          credits: {
            enabled: false
          },
          chart: {
            renderTo: 'container',
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 5,
              beta: 0,
              depth: 20,
              viewDistance: 90
            },
            margin: [40, 20, 75, 70],
            height: 280,
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: {
            text: '',
            y: 16,
            style: {
              fontSize: '18px',
              fontWeight: 400,
              color: '#DEF1FF'
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
          },
          colors: ['#ff2d2e'],
          plotOptions: {
            series: {
              negativeColor: '#00933b'
            }
          },
          xAxis: {
            categories: [],
            lineWidth: 1,
            gridLineWidth: 0,
            labels: {
              style: {
                fontFamily: 'PingFang SC',
                fontWeight: 500,
                color: '#999999'
              }
            }
          },
          yAxis: {
            // tickAmount: 4,
            gridLineWidth: 1,
            gridLineColor: 'rgba(101, 198, 231, 0.2)',
            labels: {
              style: {
                color: '#999999'
              }
            },
            title: {
              text: ''
            }
          },
          series: [{
            name: '账户盈亏',
            data: [],
            color: {
              linearGradient: [0, 0, 0, 140],
              stops: [
                [0, '#01F0FF'],
                [1, '#005559']
              ]
            }
          }],
        },
        netWorthOptions: {
          credits: {
            enabled: false
          },
          title: {
            text: '',
            y: 16,
            x: 12,
            style: {
              fontSize: '18px',
              fontWeight: 400,
              color: '#DEF1FF'
            }
          },
          chart: {
            type: 'spline',
            zoomType: 'x',
            margin: [40, 20, 75, 60],
            height: 280,
            backgroundColor: null,
            plotBackgroundColor: {
              linearGradient: [0, 0, 0, 250],
              stops: [
                [0, '#FFFFFF'],
                [1, '#E7ECF8']
              ]
            }
          },
          colors: ['#2165F9'],
          xAxis: {
            labels: {
              style: {
                color: '#999999'
              }
            },
            lineWidth: 0,
            categories: []
          },
          yAxis: {
            visible: true,
            gridLineWidth: 1,
            min: 1,
            gridLineColor: 'rgba(101, 198, 231, 0.2)',
            labels: {
              style: {
                color: '#999999'
              }
            },
            title: {
              text: ''
            }
          },
          tooltip: {

          },
          legend: {
            enabled: false
          },
          series: [
            {
              name: '累计净值',
              data: []
            }
          ]
        },
        profitOptions: {
          credits: {
            enabled: false
          },
          title: {
            text: '',
            y: 16,
            x: 12,
            style: {
              fontSize: '18px',
              fontWeight: 400,
              color: '#DEF1FF'
            }
          },
          chart: {
            type: 'line',
            zoomType: 'x',
            margin: [40, 20, 75, 60],
            height: 280,
            backgroundColor: null,
            plotBackgroundColor: {
              linearGradient: [0, 0, 0, 250],
              stops: [
                [0, '#FFFFFF'],
                [1, '#E7ECF8']
              ]
            }
          },
          colors: ['#FF5A82', '#2165F9'],
          xAxis: {
            labels: {
              style: {
                color: '#999999'
              }
            },
            lineWidth: 0,
            categories: []
          },
          yAxis: {
            visible: true,
            gridLineWidth: 1,
            gridLineColor: 'rgba(101, 198, 231, 0.2)',
            labels: {
              style: {
                color: '#999999'
              }
            },
            title: {
              text: ''
            }
          },
          tooltip: {
          },
          legend: {
            align: 'center',
            verticalAlign: 'top',

          },
          series: [
            {
              type: 'line',
              name: '累计收益',
              lineColor: 'rgba(4,205,244, 0.7)',
              color: {
                linearGradient: [0, 120, 0, 0],
                stops: [
                  [0, 'rgba(4,205,244,0)'],
                  [1, 'rgba(4,205,244,1)']
                ],
              },
              data: []
            },
            {
              type: 'line',
              name: '累计手续费',
              lineColor: 'rgba(43, 128, 255, 0.7)',
              color: {
                linearGradient: [0, 120, 0, 0],
                stops: [
                  [0, 'rgba(43, 128, 255, 0)'],
                  [1, 'rgba(43, 128, 255, 1)']
                ],
              },
              data: []
            }
          ]
        },
      }
    },
    computed: {
      ...mapState({
        user: (state) => state.user
      })
    },
    beforeCreate () {
      if (this.$store.state.accounts.length === 0) {
        this.$message({
          message: '请先添加期货监控中心账户或等待数据更新',
          type: 'warning'
        });
        this.$router.push({name: 'Account'})
      } else if (this.$store.state.curAccountId.length === 0) {
        let curAccount = this.$store.state.accounts[0];
        this.$store.commit('SET_CUR_ACCOUNT_ID', [curAccount.id]);
      }
    },
    mounted () {
      this.$store.commit('SET_ANALYSIS_TYPE', 'single');
      this.$store.dispatch('getMaxVarieties')
      this.getData()
      this.getGeneralData()
    },
    methods: {
      versionChange () {
        window.open('https://sq.dpswang.com', '_blank')
      },
      getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let accounts = []
        this.$store.state.accounts.map(item => {
          accounts.push(item.id)
        })
        const params = {
          a_id: accounts.join(','),
          type: accounts.length === 1 ? 'single' : 'multiple',
          b_date: this.$store.state.dateRange.b_date,
          e_date: this.$store.state.dateRange.e_date
        }
        let loadingWeekProfitCard = Loading.service({'target': '#weekProfit', background: 'rgba(0,0,0,0.1)'});
        apiAnalysisData('weekProfit', params).then(res => {
          if (res.data.code === 0 && res.data.data) {
            let options = JSON.parse(JSON.stringify(this.weekProfitOptions));
            const resData = JSON.parse(JSON.stringify(res.data.data))
            let categories = []
            let f1s = []
            Array.isArray(resData) && resData.map(item => {
              categories.push(item.yw)
              if (item.f1 || item.f1 === 0) {
                f1s.push(item.f1)
              }
            })
            options.xAxis.categories = categories;
            options.series = [{
              name: '周盈亏',
              data: f1s
            }];
            this.weekProfitOptions = options
            loadingWeekProfitCard.close();
          }
        });
        let loadingNetValue = Loading.service({'target': '#netWorth', background: 'rgba(0,0,0,0.1)'});
        apiAnalysisData('netValue', params).then(res => {
          if (res.data.code === 0 && res.data.data) {
            let options = JSON.parse(JSON.stringify(this.netWorthOptions));
            const resData = JSON.parse(JSON.stringify(res.data.data))
            let categories = []
            let f1s = []
            Array.isArray(resData) && resData.map(item => {
              if (item.date) {
                categories.push(item.date)
              }
              if (item.f1 || item.f1 === 0) {
                f1s.push(item.f1)
              }
            })
            options.xAxis.categories = categories;
            options.series = [{
              name: '累计净值',
              data: f1s
            }];
            options.yAxis.min = Math.min(...f1s)
            options.yAxis.max = Math.max(...f1s)
            this.netWorthOptions = options
            loadingNetValue.close()
          }
        });
        let loadingProfit = Loading.service({'target': '#profit', background: 'rgba(0,0,0,0.1)'});
        apiAnalysisData('profitCurve', params).then(res => {
          if (res.data.code === 0 && res.data.data) {
            let options = JSON.parse(JSON.stringify(this.profitOptions));
            const resData = JSON.parse(JSON.stringify(res.data.data))
            let categories = []
            let f1s = []
            let f2s = []
            Array.isArray(resData) && resData.map(item => {
              if (item.date) {
                categories.push(item.date)
              }
              if (item.f1 || item.f1 === 0) {
                f1s.push(item.f1)
              }
              if (item.f2 || item.f2 === 0) {
                f2s.push(item.f2)
              }
            })
            options.xAxis.categories = categories;
            options.series = [
              {
                name: '累计盈亏',
                data: f1s
              }, {
                name: '累计手续费',
                data: f2s
              }];
            this.profitOptions = options
            loadingProfit.close();
          }
        });
      },
      async getGeneralData () {
        if (this.$store.state.accounts.length === 0) {
          this.$message({
            message: '请先添加期货监控中心账户或等待数据更新',
            type: 'warning'
          });
          this.$router.push({name: 'Account'});
          return false;
        }
        let loadingAssetsStruct = Loading.service({'target': '#assetsStruct', background: 'rgba(0,0,0,0.1)'});
        let loadingMonthProfit = Loading.service({'target': '#accountProfit', background: 'rgba(0,0,0,0.1)'});
        let res = await apiGeneral()
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          const {asset_total, today_profit, total_profit, asset_struct, account_profit} = resData
          let categories = []
          let seriesData = []
          let pieData = []
          for (const key in account_profit) {
            const userAccount = this.$store.state.accounts.filter(item => item.id === Number(key))
            if (userAccount[0]) {
              categories.push(userAccount[0].nickname)
              seriesData.push(account_profit[key])
            }
          }
          for (const key in asset_struct) {
            const userAccount = this.$store.state.accounts.filter(item => item.id === Number(key))
            if (userAccount[0]) {
              pieData.push(
                [
                  userAccount[0].nickname,
                  asset_struct[key]
                ])
            }
          }
          this.assetTotal = asset_total;
          this.todayProfit = today_profit;
          this.totalProfit = total_profit;
          let structOptions = JSON.parse(JSON.stringify(this.structOptions));
          structOptions.series = [
            {
              type: 'pie',
              name: '占比',
              radius: ['30%', '70%'],
              data: pieData
            }
          ];
          this.structOptions = structOptions;

          let profitOptions = JSON.parse(JSON.stringify(this.accountProfitOptions));
          profitOptions.xAxis.categories = categories;
          profitOptions.series = [
            {
              name: '盈亏',
              data: seriesData
            }
          ];
          this.accountProfitOptions = profitOptions;
          loadingAssetsStruct.close();
          loadingMonthProfit.close()
        }
      },
      handleCommand (command) {
        if (command === "logout") {
          this.$message({
            message: "退出成功",
            type: "success"
          })
          localStorage.clear()
          this.$store.commit('SET_TOKEN', '')
          this.$store.commit('SET_ACCOUNTS', [])
          this.$store.commit('SET_CUR_ACCOUNT_ID', [])
          this.$store.commit('SET_ALL_ACCOUNTS', [])
          this.$store.commit('SET_ANALYSIS_TYPE', 'single')
          this.$store.commit('SET_USER', null)
          this.$router.replace('/login')
        }
      },
      jumpToAnalysis (index) {
        switch (index + 1) {
          case 0:
            this.$router.push({path: '/general'})
            break
          case 1:
            this.$router.push({path: '/analysis/report'})
            break
          case 2:
            this.$router.push({path: '/analysis/netValue'})
            break
          case 3:
            this.$router.push({path: '/analysis/takeCycle'})
            break
          case 4:
            this.$router.push({path: '/analysis/singleVariety'})
            break
          case 5:
            this.$router.push({path: '/analysis/dailySett'})
            break
          case 6:
            this.$router.push({path: '/account'})
            break
          case 7:
            this.$router.push({path: '/analysis/kline'})
            break
        }
      },
      goSite () {
        location.href = "https://www.dpswang.com";
      },
    }
  }
</script>

<style lang="scss" scoped>
    .home {
        margin: 0;
        min-width: 100%;
        width: fit-content;
        box-sizing: border-box;
        background-color: #f5f5f5;
        padding: 0 0 30px;

        .header {
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: url("../assets/home/header_bg.png") no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-weight: bolder;

            .logo-col {
                display: flex;
                align-items: center;

                .logo {
                    margin-left: 60px;
                    width: 216px;
                    height: 38px;
                }
            }

            .title-col {
                display: flex;
                align-items: center;
                justify-content: center;

                .title {
                    width: 347px;
                    height: 31px;
                }
            }

            .account {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 60px;

                .el-dropdown {
                    height: 40px;
                    display: flex;
                    align-items: center;
                }

                .text {
                    cursor: pointer;
                    padding: 0 5px;
                    /*margin-right: 30px;*/
                    height: 40px;
                    font-size: 16px;
                    line-height: 40px;
                    background-size: contain;
                    display: flex;
                    align-items: center;

                    i {
                        margin-top: -2px;
                        font-size: 24px;
                        color: #fff;
                        line-height: 40px;
                    }

                    &:hover {
                        color: #fff;
                    }

                    &:hover i {
                        color: #FFFFFF;
                    }
                }
            }
        }

        .main {
            flex: 1;
            padding: 0 20px;
            display: flex;

            .el-row {
                flex: 1;

                .el-col {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    position: relative;

                    .center-box {
                        width: 100%;
                        height: 400px;
                        padding: 80px 0;
                        position: relative;
                        margin-top: 20px;
                        .money-box {
                            width: fit-content;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 16px;
                            font-weight: 400;
                            color: #666666;
                            text-shadow: 0 1px 4px rgba(23, 119, 255, 0.34);
                            .title {
                                font-size: 24px;
                            }
                            .number {
                                color: #1777FF;
                            }
                        }
                    }
                    .card-box {
                        margin-top: 20px;
                        width: 100%;
                        height: 80px;
                        display: flex;
                        justify-content: space-around;

                        .card {
                            padding: 10px 0;
                            background: #FFFFFF;
                            box-shadow: 0 2px 10px 0 rgba(20, 105, 226, 0.2);
                            border-radius: 10px;
                            color: #333333;
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 400;
                            display: flex;
                            justify-content: center;

                            .title {
                            }

                            .number {
                                &.positive {
                                    color: #AA0000;
                                }

                                &.negative {
                                    color: #10D142;
                                }
                            }
                        }
                    }
                }
            }

            .sm-chart {
                /*background: url("../assets/home/chart_bg_sm.png") no-repeat;*/
                /*background-size: 100% 100%;*/
                background: #FFFFFF;
                box-shadow: 0 0 16px 0 rgba(15, 41, 87, 0.1);
                border-radius: 4px;
            }

            .chart-box {
                margin-top: 20px;
                width: 100%;
                height: fit-content;
                background: #FFFFFF;
                box-shadow: 0 0 16px 0 rgba(15, 41, 87, 0.1);
                border-radius: 4px;

                .title {
                    width: 100%;
                    height: 40px;
                    background: #1777FF;
                    border-radius: 4px 4px 0 0;
                    font-size: 18px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 40px;
                }

                .lg-chart {
                    /*background: #FFFFFF;*/
                    /*box-shadow: 0 0 16px 0 rgba(15, 41, 87, 0.1);*/
                    /*border-radius: 4px;*/
                }
            }
        }

        .tabs {
            width: 60%;
            min-width: 800px;
            height: 140px;
            margin: 40px auto 10px;

            .tab-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                cursor: pointer;

                .tab {
                    width: 115px;
                    height: 115px;
                    background: #FFFFFF;
                    box-shadow: 0 2px 10px 0 rgba(20, 105, 226, 0.2);
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.active {
                        background-color: #1777FF;
                    }
                    .icon {
                        width: 45px;
                        height: 45px;
                    }
                }

                .text {
                    font-size: 18px;
                    line-height: 20px;
                    margin: 20px 0;
                    font-weight: 400;
                    color: #1777FF;
                }
            }
        }
    }
</style>
<style lang="scss">
    .mt10 {
        margin-top: 10px;
    }

    .mt20 {
        margin-top: 20px;
    }

    .home {
        .cycle {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("../assets/home/ring_bg.png") no-repeat center 8px;
            background-size: 344px 290px;

            .el-progress-circle {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 71% !important;
                height: 71% !important;
                margin: auto;
            }
        }

        .el-progress path:first-child {
            stroke: rgba(251, 254, 255, 0);
        }
    }

    .account-menu {
        /*background: rgba(0, 105, 211, 0.7);*/
        /*color: #00F6FF;*/
        /*border: none;*/
        /*border-radius: 4px;*/
    }
</style>
